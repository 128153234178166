import { Route } from '@angular/router';
import { HomeComponent } from './home.component';


export const HomeRoutes: Route[] = [
  {
    path: 'homeXXX',
    component: HomeComponent
  }
];
