import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TermsComponent} from './terms.component';
import {CustomMaterialModule} from '../custom-material/index';

@NgModule({
  imports: [FormsModule, CommonModule, RouterModule, CustomMaterialModule],
  declarations: [TermsComponent],
  exports: [TermsComponent]
})

export class TermsModule {
}
