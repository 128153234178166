import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-top-nav',
    templateUrl: 'topnav.html',
    styleUrls: ['./topnav.scss']
})

export class TopNavComponent {

}
