import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'app-footer-nav',
    templateUrl: 'footer.html',
    styleUrls: ['./footer.scss']
})

export class FooterComponent {

}
